import { useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom"
import { AppContext } from "../context/UserContext";
import { BACKEND_URL } from "../configs/config";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from 'react-router-dom'
import { GrBitcoin } from "react-icons/gr";
import { SiBitcoin } from "react-icons/si";
import { CiSettings } from "react-icons/ci";
import { FiFilePlus } from "react-icons/fi";
import { HiOutlineChartBar } from "react-icons/hi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { PiLightningLight } from "react-icons/pi";
import { HiOutlinePaperAirplane } from "react-icons/hi";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { CiUser } from "react-icons/ci";
import { FaRegCircleUser } from "react-icons/fa6";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { CgSupport } from "react-icons/cg";
import { TbLogout } from "react-icons/tb";
import { GiRuneStone } from "react-icons/gi";
import { SiSketchfab } from "react-icons/si";
import { ImFire } from "react-icons/im";
import { GiShop } from "react-icons/gi";


export const Sidebar = () => {
    const menuOptions1 = [
        {
            icon: <GiRuneStone color="white"/>,
            label: 'Mint Runes',
            link: '/mintrunes',
        },
        {
            icon: <SiSketchfab  color="white"/>,
            label: 'Etch Runes',
            link: '/etchrunes',
        },
        {
            icon: <ImFire  color="white"/>,
            label: 'Burn Runes',
            link: '/burnrunes',
        },
        {
            icon: <GiShop color="white"/>,
            label: 'Rune Marketplace',
            link: '/runemarketplace',
        },
        {
            icon: <FiFilePlus color="white"/>,
            label: 'Draft Transaction',
            link: '/draft-transaction',
        },
        {
            icon: <IoDocumentTextOutline color="white"/>,
            label: 'In Progress',
            link: '/in-progress',
        },
        {
            icon: <HiOutlineChartBar color="white" />,
            label: 'Mempool',
            link: '/mempool',
        },
        {
            icon: <PiLightningLight color="white"/>,
            label: 'Speed Up(RBF)',
            link: '/rbf',
        },
        {
            icon: <HiOutlinePaperAirplane color="white"/>,
            label: 'Send',
            link: '/send',
        },
        {
            icon: <HiOutlineViewGridAdd  color="white"/>,
            label: 'Portfolio',
            link: '/portfolio',
        },
        {
            icon: <CiUser color="white" />,
            label: 'Profile',
            link: '/profile',
        },
        {
            icon: <FaRegCircleUser color="white"/>,
            label: 'Leaderboard',
            link: '/users',
        },
        {
            icon: <MdOutlineNotificationsActive color="white"/>,
            label: 'Notifications',
            link: '/notificatoins',
        },
    ];
    const menuOptions2 = [
        {
            icon: <CiSettings color="white"/>,
            label: 'Settings',
            link: '/settings',
            color: 'text-white',
            onClick: () => handleSettingsClick(),
        },
        {
            icon: <CgSupport color="white"/>,
            label: 'Help',
            link: '/help',
            color: 'text-white',
            onClick: () => handleHelpClick(),
        },
        {
            icon: <TbLogout color="#FF7D7D"/>,
            label: 'Logout',
            link: '/logout',
            color: 'text-[#FF7D7D]',
            onClick: () => handleLogoutClick(),
        },
    ];

    const appContext = useContext(AppContext)
    const location = useLocation();
    const navigate = useNavigate();

    const [menuIndex, setMenuIndex] = useState(-1);
    const [otherIndex, setOtherIndex] = useState(-1);
    const handleNavigate = (index: number) => {
        if (appContext?.paymentAddress) {
            setMenuIndex(index)
        } else {
            toast.warn("You should Connect Wallet!")
        }
    }

    const handleHelpClick = () => {

    }

    const handleLogoutClick = () => {
        appContext?.updatePaymentAddress("");
        navigate("/")
        localStorage.clear();
    }

    const handleSettingsClick = () => {
        console.log(appContext?.paymentAddress);
    }


    return (
        <div className="flex flex-row max-w-[2500px]">

            <div className="flex flex-col max-[565px]:hidden border-r-[0.5px] border-[#20232A] max-[750px]:min-w-[80px] min-w-[246px] min-h-screen pr-[16px] pl-[16px] pt-5 h-screen overflow-auto" id="side-bar"
            >
                {/* logo */}
                <div className="flex flex-row items-center mx-auto max-[750px]:hidden">
                    <img src="../assets/Frame.png" alt="logo" className="brightness-150" />
                </div>
                <div className="flex flex-row items-start mx-auto min-[750px]:hidden">
                    <img src="../assets/Frame1.png" alt="logo" />
                </div>

                <div className="flex flex-col justify-between h-screen gap-20 mt-6">
                    {/* mainsidebar */}
                    <div className="flex flex-col  max-h-[532px]">
                        {appContext?.paymentAddress ? (menuOptions1.map((option, index) =>
                            menuIndex != index ?
                                (<Link to={option.link} key={`whole-${index}`} onClick={() => handleNavigate(index)}
                                >
                                    <div key={index} className="flex flex-row justify-start  pr-[16px] pb-[10px] pt-[8px] pl-[12px]  max-[750px]:justify-center hover:bg-[#154360] hover:rounded-xl focus:bg-[#1665FF] ">
                                        <button className="flex flex-row items-center gap-2 ">
                                            <div>{option.icon}</div>
                                            <p className="text-white text-sm leading-6 font-semibold font-manrope max-[750px]:hidden">
                                                {option.label}
                                            </p>
                                        </button>
                                    </div>
                                </Link>
                                ) :
                                (<Link to={option.link} key={`abbr-${index}`} onClick={() => setMenuIndex(index)} >
                                    <div key={index} className="flex flex-row justify-start pr-[16px] pb-[10px] pt-[8px] pl-[12px] rounded-xl max-[750px]:justify-center hover:bg-[#154360] focus:bg-[#1665FF] bg-[#1665FF]">
                                        <button className="flex flex-row items-center gap-2 ">
                                            <div>{option.icon}</div>
                                            <p className="text-white text-sm leading-6 font-semibold font-manrope max-[750px]:hidden">
                                                {option.label}
                                            </p>
                                        </button>
                                    </div>
                                </Link>)
                        )) : (null)}
                    </div>

                    {/* others */}
                    <div className="flex flex-col max-w-[204px] mt-auto mb-3">
                        <div className="flex flex-row justify-start pr-[16px] pt-[12px] pb-[12px] pl-[16px] max-[750px]:justify-center hover:bg-[#4665FF] hover:rounded-xl focus:bg-[#1665FF] "
                            onClick={() => toast.info("Donate is coming soon!!")}
                        >
                            <button
                                className="flex flex-row items-center gap-2"
                            >
                                <SiBitcoin  color="#ff9900" />
                                <p className={`text-[#ff9900] text-sm leading-6 font-semibold font-manrope max-[750px]:hidden`}>
                                    Donate
                                </p>
                            </button>
                        </div>
                        {menuOptions2.map((option, index) => (
                            otherIndex != index ? (
                                // <Link to={option.link} key={`whole-${index}`} onClick={() => handleNavigate(index)}
                                // >
                                <div key={index} className="flex flex-row justify-start  pr-[16px] pt-[8px] pb-[8px] pl-[16px] max-[750px]:justify-center hover:bg-[#4665FF] hover:rounded-xl focus:bg-[#1665FF] "
                                    onClick={option.onClick}
                                >
                                    <button
                                        className="flex flex-row items-center gap-2"
                                    >
                                        <div>{option.icon}</div>
                                        <p className={`${option.color} text-sm leading-6 font-semibold font-manrope max-[750px]:hidden`}>
                                            {option.label}
                                        </p>
                                    </button>
                                </div>
                                // </Link>
                            ) : (
                                // <Link to={option.link} key={`abbr-${index}`} onClick={() => setOtherIndex(index)} >
                                <div key={index} className="flex flex-row justify-start  pr-[16px] pt-[8px] pb-[8px] pl-[16px] max-[750px]:justify-center rounded-xl bg-[#1665FF] "
                                    onClick={option.onClick}
                                >
                                    <button
                                        className="flex flex-row items-center gap-2"
                                    >
                                        <div>{option.icon}</div>
                                        <p className={`${option.color} text-sm leading-6 font-semibold font-manrope max-[750px]:hidden`}>
                                            {option.label}
                                        </p>
                                    </button>
                                </div>
                                // </Link>
                            )
                        ))}
                    </div>
                </div>
            </div>

            <ToastContainer />
            <Outlet />
        </div>
    )
}




