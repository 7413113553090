import { useContext, useEffect, useState } from 'react'
import Notiflix, { Loading } from "notiflix";
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Slider, Table, Pagination, Input } from '@mantine/core';
import axios from 'axios';
import { MdOutlineManageSearch } from "react-icons/md";

import Mydrawer from '../component/collapse'
import { AppContext } from '../context/UserContext';
import { ToastContainer } from 'react-toastify';
import { IRuneDetails, IRuneList, IRuneMarketplace } from '../type';
import Header from '../component/Header';
import { BACKEND_URL, L_WIDTH, MD_WIDTH, S_WIDTH, XL_WIDTH, XS_WIDTH } from '../configs/config';
import { LoadingInitialInfo } from '../component/LoadingUpdate';
import { Tooltip as ReactTooltip, Tooltip } from 'react-tooltip'
import { useNavigate, useNavigation } from 'react-router-dom';


export default function RuneMarketplace() {

    const appContext = useContext(AppContext);
    const navigation = useNavigate()

    const [opened, { open, close }] = useDisclosure(false);
    const [runelist, setRuneList] = useState<IRuneMarketplace[]>();
    const [runeDetailsList, setRuneDetailsList] = useState<any[]>();
    const [selectedRune, setSelectedRune] = useState<IRuneMarketplace>();
    const [selectedDetailsRune, setSelectedDetailsRune] = useState<IRuneDetails>();
    const [totalCount, setTotalCount] = useState(0);
    const [searchWord, setSearchWord] = useState("");
    const [modalFlag, setModalFlag] = useState("mint");
    const [width, setWidth] = useState(0);
    const [page, setPage] = useState(1);

    const [hoverElem, setHoverElem] = useState<IRuneDetails>();

    Loading.custom({
        svgColor: "orange"
    })

    const fetchRuneList = async () => {
        const url = `${BACKEND_URL}/marketplace/getMarketplaceRuneList`;
        const payload: any = (await axios.get(url)).data.payload;
        console.log("fetchRuneList ==> ", payload);
        if (payload) {
            // setRuneList(payload);
            let tempRuneList: IRuneMarketplace[] = [];
            payload.list.map((elem: any, index: number) => {
                tempRuneList.push({
                    index: index + 1,
                    name: elem.tick,
                    volumn: elem.amountVolume,
                    etching: elem.deployTime,
                    holders: elem.holders,
                    marketCap: elem.cap,
                    price: elem.curPrice
                })
            })
            setRuneList(tempRuneList);
            setRuneDetailsList(payload.list);
            setTotalCount(Math.ceil(payload.totalCount / 10));

            Notiflix.Loading.remove();
        }
    }

    const openMintModal = async (elem: IRuneMarketplace) => {
        setSelectedRune(elem);
        open();
        setModalFlag("mint");
    }

    const onChangeWidth = () => {
        const temp = window.innerWidth;
        console.log("Screen width  ==> ", temp);
        setWidth(temp);
    }

    const onDetailsPage = (tick: string) => {
        if (!runeDetailsList) return;
        navigation(`/runemarketplace/${tick}`);
    }

    useEffect(() => {
        LoadingInitialInfo();
        onChangeWidth();
        fetchRuneList();
    }, [])

    return (
        <div className="flex w-full min-h-screen mx-auto ">
            <div className="flex flex-col w-full min-h-screen">

                {/* user profile */}
                <div className="flex flex-row justify-between px-4 py-8 ">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" alt="Image" />
                    </div>
                    <div className="max-[563px]:hidden"></div>

                    {/* Header */}
                    <Header />
                </div>

                {/* Title */}
                <div className='flex flex-row justify-between px-8 '>
                    <p className="bold text-[32px] text-white leading-10 font-protoMono">Runes Marketplace</p>
                    <div></div>
                </div>

                {/* table */}
                {runelist ?
                    <div className='flex flex-col mx-6 mt-10 gap-4 overflow-auto'>
                        {/* <Input className='w-80' placeholder="Rune ticker" leftSection={<MdOutlineManageSearch />} onChange={(e) => setSearchWord(e.target.value)} /> */}
                        <div className='h-[calc(100vh-300px)] overflow-auto border border-white'>
                            <Table className='text-white'>
                                <Table.Thead>
                                    <Table.Tr>
                                        <Table.Th className='w-10'>#</Table.Th>
                                        {width > S_WIDTH ? <Table.Th className='w-28'>Name</Table.Th> : <></>}
                                        {width > MD_WIDTH ? <Table.Th>Volume (24h)</Table.Th> : <></>}
                                        {width > S_WIDTH ? <Table.Th className='w-10'>Price (sats)</Table.Th> : <></>}
                                        {width > S_WIDTH ? <Table.Th className='w-20'>Market Cap</Table.Th> : <></>}
                                        {width > S_WIDTH ? <Table.Th className='w-20'>Holders</Table.Th> : <></>}
                                        <Table.Th className='w-20'>Etching Time</Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>
                                    {runelist.map((element: IRuneMarketplace, index: number) => (
                                        <Table.Tr key={element.index} className='cursor-pointer hover:brightness-150 duration-300'>
                                            <Table.Td onClick={() => onDetailsPage(element.name)} >{Number(element.index).toLocaleString()}</Table.Td>
                                            <Table.Td className='flex flex-row gap-2 items-center' onClick={() => onDetailsPage(element.name)} >
                                                <img alt='rune icon' src={`https://api-t2.unisat.io/icon-v1/icon/runes/${element.name}`} className='w-6 h-6'></img>
                                                <div>{element.name}</div>
                                            </Table.Td>
                                            {width > MD_WIDTH ? <Table.Td onClick={() => onDetailsPage(element.name)} >{Number(element.volumn).toLocaleString()}</Table.Td> : <></>}
                                            {width > S_WIDTH ? <Table.Td onClick={() => onDetailsPage(element.name)} >{Number(element.price).toLocaleString()}</Table.Td> : <></>}
                                            {width > S_WIDTH ? <Table.Td onClick={() => onDetailsPage(element.name)} >{Number(element.marketCap).toLocaleString()}</Table.Td> : <></>}
                                            {width > S_WIDTH ? <Table.Td onClick={() => onDetailsPage(element.name)} >{Number(element.holders).toLocaleString()}</Table.Td> : <></>}
                                            <Table.Td onClick={() => onDetailsPage(element.name)} >{Number(element.etching).toLocaleString()}</Table.Td>
                                        </Table.Tr>
                                    ))}
                                </Table.Tbody>
                            </Table>
                        </div>
                        {/* <Pagination total={totalCount} autoContrast color="lime.4" value={page} onChange={setPage} className='mt-4 flex mx-auto' /> */}
                    </div> : <></>}
                <Tooltip anchorSelect=".tooltip-target" place='top' className='flex flex-col'>
                    <div className='flex flex-col text-[20px] font-medium'>
                        <p className='text-gray-500 '>Total Progress</p>
                        <p className='text-gray-100 '>
                            {hoverElem && ((Number(hoverElem?.mints) * Number(hoverElem.terms.amount) + Number(hoverElem.premine)) / Math.pow(10, hoverElem.divisibility)).toLocaleString()}
                            {" "} /{" "}
                            {hoverElem && (BigInt(Number(hoverElem?.terms.cap) * Number(hoverElem.terms.amount) / Math.pow(10, hoverElem?.divisibility)) + BigInt(Number(hoverElem.premine) / Math.pow(10, hoverElem?.divisibility))).toLocaleString()}</p>
                    </div>
                    <div className='flex flex-col text-[20px] font-medium'>
                        <p className='text-gray-500 '>Mint Progress</p>
                        <p className=' text-orange-600'>
                            {hoverElem && ((Number(hoverElem?.mints) * Number(hoverElem.terms.amount)) / Math.pow(10, hoverElem.divisibility)).toLocaleString()}
                            {" "} /{" "}
                            {hoverElem && (BigInt(Number(hoverElem?.terms.cap) * Number(hoverElem.terms.amount) / Math.pow(10, hoverElem?.divisibility))).toLocaleString()}</p>
                    </div>
                    <div className='flex flex-col text-[20px] font-medium'>
                        <p className='text-gray-500 '>Premine</p>
                        <p className='text-yellow-400 '>
                            {hoverElem && (Number(hoverElem.premine) / Math.pow(10, hoverElem.divisibility)).toLocaleString()}</p>
                    </div>
                </Tooltip>
            </div>
            <Modal opened={opened && modalFlag == "details"} onClose={close} centered withCloseButton={false} >
                {/* Modal content */}
                {selectedDetailsRune ?
                    <div className="flex flex-col gap-2 bg-[#191D24] mx-auto border-solid border-[#252B35] text-white">
                        <p className='text-white font-bold text-xl text-center mt-4'>Rune Details</p>
                        <div className='flex flex-col gap-4 p-6 '>
                            <div className='w-full flex flex-col justify-between mx-0 gap-2 p-4 rounded-lg bg-gray-800'>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>Rune</p>
                                    <p className='font-bold text-[17px]'>{selectedDetailsRune.spacedRune}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>Rune Id</p>
                                    <p className='font-bold text-[17px]'>{selectedDetailsRune.runeid}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>timestamp</p>
                                    <p className='font-bold text-[17px]'>{new Date(selectedDetailsRune.timestamp * 1000).toLocaleString()}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>ethcing</p>
                                    <p className='font-bold text-[17px] truncate w-2/3'>{selectedDetailsRune.etching}</p>
                                </div>
                            </div>

                            <div className='flex flex-col gap-2'>
                                <div className='w-full flex flex-col justify-center mx-0 gap-2 p-4 rounded-lg bg-gray-800'>
                                    <div className='w-full flex flex-row justify-between'>
                                        <p className=''>Mint Progress</p>
                                        <p>{Number(((Number(selectedDetailsRune.supply) - Number(selectedDetailsRune.premine)) * 100 / (Number(selectedDetailsRune.terms.amount) * Number(selectedDetailsRune.terms.cap))).toFixed(2)).toLocaleString()}%</p>
                                    </div>
                                    <div className="h-2 w-full bg-gray-700 relative overflow-hidden">
                                        <div
                                            className="absolute inset-0 left-0 bg-gray-500"
                                            style={{
                                                width: `${Math.floor(Number(((Number(selectedDetailsRune.supply) - Number(selectedDetailsRune.premine)) * 100 / (Number(selectedDetailsRune.terms.amount) * Number(selectedDetailsRune.terms.cap))).toFixed(2)))}%`, // Dynamically set the width
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full flex flex-col justify-between mx-0 gap-2 p-4 rounded-lg bg-gray-800'>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>start</p>
                                    <p className='font-bold text-[17px]'>{String(selectedDetailsRune.terms.heightStart)}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>end</p>
                                    <p className='font-bold text-[17px]'>{String(selectedDetailsRune.terms.heightEnd)}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>amount</p>
                                    <p className='font-bold text-[17px]'>{selectedDetailsRune.terms.amount}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>mints</p>
                                    <p className='font-bold text-[17px]'>{selectedDetailsRune.mints}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>cap</p>
                                    <p className='font-bold text-[17px]'>{selectedDetailsRune.terms.cap}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>mintable</p>
                                    <p className='font-bold text-[17px]'>true</p>
                                </div>
                            </div>
                        </div>
                    </div> : <></>}
            </Modal>
            <ToastContainer />
        </div>
    )
}

