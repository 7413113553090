import axios from "axios"
import { BACKEND_URL } from "../configs/config";

export const runeEtchingController = async (runeName: string, runeAmount: string, runeSymbol: string) => {
    const url = `${BACKEND_URL}/rune/pre-rune-etch`;
    const payload = await axios.post(url, {
        runeName, runeAmount, runeSymbol
    });
    console.log("rune Etching Controller ==> ", payload);
    return payload.data;
}

export const runeBurnController = async (runeId: string, runeAmount: string, ordinalsAddress: string, ordinalsPublickey: string, paymentAddress: string, paymentPublickey: string) => {
    const url = `${BACKEND_URL}/rune/pre-rune-burn`;
    const payload = await axios.post(url, {
        runeId, runeAmount, ordinalsAddress, ordinalsPublickey, paymentAddress, paymentPublickey
    });
    console.log("rune Burnning Controller ==> ", payload.data);
    return payload.data;
}

export const runeBurnBraodcastingController = async (signedPsbt: string, runeId: string) => {
    const url = `${BACKEND_URL}/rune/after-rune-burn`;
    const payload = await axios.post(url, {signedPsbt, runeId});
    console.log("rune Burnning Controller ==> ", payload.data);
    return payload.data;
}