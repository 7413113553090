import { AppContext, AppProvider } from "./context/UserContext";
import { useContext, useEffect } from "react";
import { Profile } from "./pages/profile";
import { Users } from "./pages/users";
import { ConnectContext } from './context';
import { PrimeReactProvider } from 'primereact/api';
import {
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Sidebar } from "./pages/sidebar";
import Membular from "./pages/membular";
import { Rbf } from "./pages/rbf";
import { Send } from "./pages/send";
import { Portfolio } from "./pages/portfolio";
import Mempool from "./pages/mempool";
import Progress from "./pages/progress";
import Details from "./pages/details";
import NotFound from "./pages/not-fonud";
import DraftTransaction from "./pages/draftTransaction";
import CommingSoon from "./pages/comming";
import RuneMint from "./pages/runemint";

import 'react-tooltip/dist/react-tooltip.css'
import EtchRune from "./pages/etchrune";
import RuneBurn from "./pages/runeburn";
import RuneMarketplace from "./pages/runemarketplace";
import RuneMarketplaceDetails from "./pages/runemarketplacedetails";

function App() {

  const appContext = useContext(AppContext);
  const isPaymentAddressSet = appContext?.paymentAddress !== "";

  return (
    <PrimeReactProvider>
      <ConnectContext>
        <AppProvider>
          <div className="bg-[url(./assets/Mempool.png)] bg-cover w-full overflow-hidden max-w-[2550px] mx-auto Proto-Mono">
            <Routes >
              <Route path='/' element={<Sidebar />}>
                <Route
                  index
                  element={
                    <Membular />
                  }
                />
                <Route
                  path="/profile"
                  element={
                    isPaymentAddressSet ? <Profile /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/users"
                  element={
                    isPaymentAddressSet ? <Users /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/rbf"
                  element={
                    isPaymentAddressSet ? <Rbf /> : <Navigate to="/" />
                  } />

                <Route
                  path="/send"
                  element={
                    isPaymentAddressSet ? <Send /> : <Navigate to="/" />
                  } />

                <Route
                  path="/portfolio"
                  element={
                    isPaymentAddressSet ? <Portfolio /> : <Navigate to="/" />
                  } />
                <Route
                  path="/mempool"
                  element={
                    isPaymentAddressSet ? <Mempool /> : <Navigate to="/" />
                  } />
                <Route
                  path="/in-progress"
                  element={
                    isPaymentAddressSet ? <Progress /> : <Navigate to="/" />
                  } />
                <Route
                  path="/draft-transaction"
                  element={
                    isPaymentAddressSet ? <DraftTransaction /> : <Navigate to="/" />
                  } />
                <Route
                  path="/detail/:id"
                  element={
                    isPaymentAddressSet ? <Details /> : <Navigate to="/" />
                  } />
                <Route
                  path="/notificatoins"
                  element={
                    isPaymentAddressSet ? <CommingSoon /> : <Navigate to="/" />
                  } />
                <Route
                  path="/mintrunes"
                  element={
                    isPaymentAddressSet ? <RuneMint /> : <Navigate to="/" />
                  } />
                <Route
                  path="/etchrunes"
                  element={
                    isPaymentAddressSet ? <EtchRune /> : <Navigate to="/" />
                  } />
                <Route
                  path="/burnrunes"
                  element={
                    isPaymentAddressSet ? <RuneBurn /> : <Navigate to="/" />
                  } />
                <Route
                  path="/runemarketplace"
                  element={
                    isPaymentAddressSet ? <RuneMarketplace /> : <Navigate to="/" />
                  } />
                <Route
                  path="/runemarketplace/:tick"
                  element={
                    isPaymentAddressSet ? <RuneMarketplaceDetails /> : <Navigate to="/" />
                  } />

                <Route
                  path="*"
                  element={
                    <NotFound />
                  } />

              </Route>

            </Routes>
          </div>
        </AppProvider>
      </ConnectContext >
    </PrimeReactProvider>

  );
}

export default App;
