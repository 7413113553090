import { useContext, useEffect, useRef, useState } from 'react'
import Mydrawer from '../component/collapse'
import axios from 'axios';
import { AppContext } from '../context/UserContext';
import { IProfile, IRbf, ITransaction, ITxFeature } from '../type';
import { IoCopyOutline, IoLogOutOutline } from 'react-icons/io5';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { BACKEND_URL, BACKEND_URL_PURE } from '../configs/config';
import { Loading } from '../component/loading';
import Notiflix from 'notiflix';
import { LoadingInitialInfo } from '../component/LoadingUpdate';

function Details() {
    const navigate = useNavigate();
    const appContext = useContext(AppContext)

    const [profile, setProfile] = useState<IProfile>();
    const [headerFlag, setHeaderFlag] = useState(false);
    const [showRBF, setShowRBF] = useState(false);

    const [txFeature, setTxFeature] = useState<ITxFeature>();
    const [rbfHistory, setRbfHistory] = useState<IRbf[]>();
    const [row, setRow] = useState<ITransaction>();

    const newFeeRef = useRef(null);
    const walletType = appContext?.walletType;
    const [isloading, setIsloading] = useState(true);

    const { id } = useParams();
    console.log("id ==> ", id);

    const getTxById = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/tx/txDetails?txId=${id}`);
            if (response.data.success) {
                console.log("TxFeature ==> ", response.data.payload);
                return response.data.payload
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            throw error;
        }
    };

    const fetchSpecificTx = async () => {
        try {
            const data = await getTxById();
            setTxFeature(data);

            console.log("data.txDetails ==> ", data.txDetails);
        } catch (error) {
            // Display an error message to the user
        }
    };

    const fetchRBFHistory = async () => {
        try {
            // ${BACKEND_URL}/utils/RBFhistory?txID=4059bbdd88f3ca433fecbceb669de24ec25634e02a59f745e1d7b45acb20cdc5
            const url = `${BACKEND_URL}/utils/RBFhistory?txID=${id}`;
            const rbfPayload = await axios(url);
            if (rbfPayload.data.success) {
                console.log("rbfPayload.data.payload ==> ", rbfPayload.data.payload);
                setRbfHistory(rbfPayload.data.payload);
            } else {
                toast.error("Fetching RBF history failed!!");
            }
        } catch (error) {
            toast.error("Fetching RBF history failed!!");
        }
    }

    const fetchRow = async () => {
        try {
            const url = `${BACKEND_URL}/tx/txById?txId=${id}`;
            const payload = await axios.get(url);
            setRow(payload.data.payload);
        } catch (error) {
            console.log(error);
        }
    }

    const init = async () => {
        try {
            await fetchSpecificTx();
            await fetchRBFHistory();
            await fetchRow();

            setIsloading(false);
        } catch (error) {

        }
    }

    useEffect(() => {
        init();
    }, []);

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(`${appContext?.paymentAddress}`);

            toast.success("address was copied!");

        } catch (err) {

            toast.warn("Not enough NOME tokens");
            alert("Copy to clipboard failed.");
        }
    };

    const handleLogoutClick = () => {
        navigate("/")
        localStorage.clear();
        appContext?.updatePaymentAddress("")
    }

    const showFirstSeen = () => {
        if (txFeature) {
            const time = txFeature?.firstSeen;
            if (time > 60 * 24) {
                return Math.floor(time / (60 * 24)) + "day ago";
            } else if (time > 60) {
                return Math.floor(time / 60) + "hours ago";
            } else if (time == 0) {
                return "Confirmed"
            } else {
                return Math.floor(time) + "min ago";
            }
        }
    }

    const showFeeUSD = () => {
        if (txFeature)
            return Math.floor(txFeature.btcPricePayload * (txFeature.fee / (10 ** 8)));
    }

    const showTotalValue = () => {
        let sum = 0;
        txFeature?.txDetails.vout.map(vout => {
            sum += vout.value;
            console.log("vout.value ==> ", vout.value);
        });
        return (sum / (10 ** 8)).toFixed(8);
    }

    const showRbfPeriod = () => {
        if (rbfHistory) {
            const time = (rbfHistory[1].time - rbfHistory[0].time);
            console.log("time ==> ", time);

            if (Math.floor(time / (3600 * 24))) return Math.floor(time / (3600 * 24)) + " day(s)"
            else if (Math.floor(time / (3600))) return Math.floor(time / (3600)) + " hour(s)"
            else if (Math.floor(time / 60)) return Math.floor(time / 60) + " min(s)"
            else return time + " sec"
        }
    }

    const closeHandle = () => {
        setShowRBF(false);
    }

    const rbfHandler = async (payload: any) => {
        const url = `${BACKEND_URL}/tx/rbf`
        const rbfResult = await axios.post(url, payload);
        console.log("rbfResult ==> ", rbfResult.data);

        return rbfResult.data;
    }

    const rbfSubmitFunc = async () => {
        console.log("rbfSubmitFunc row ==> ", row);
        const newFeeRate = (newFeeRef.current as any).value;
        let payload = {}
        if (newFeeRate) {
            payload = {
                txId: row?.txId,
                type: walletType,
                newFeeRate,
            }
        }

        console.log("payload in rbfSubmitFunc ==> ", payload);
        const psbt = await rbfHandler(payload);

        console.log('psbt.psbtHex in RBF ==> ', psbt.psbtHex);
        const signedPSBT = await (window as any).unisat.signPsbt(psbt.psbtHex);
        console.log("signedPSBT ==> ", signedPSBT);

        console.log("payload ==> ", {
            psbt: psbt.psbtHex,
            signedPSBT,
            walletType,
            txId: row?.txId,
            feeRate: newFeeRate,
        })

        const txIdPayload = await axios.post(`${BACKEND_URL}/tx/rbf-exec`, {
            psbt: psbt.psbtHex,
            signedPSBT,
            walletType,
            txId: row?._id,
            feeRate: newFeeRate,
        })
        const txId = txIdPayload.data.msg;

        console.log("txID ==> ", txId);
    }

    const rbfModalHandler = async () => {
        setShowRBF(true);
        console.log("row ==> ", row);
    }

    if (isloading) {
        LoadingInitialInfo();
    }

    return (

        <div className="flex w-full min-h-screen bg-black ">
            <div className="flex flex-col w-full ">

                {/* user profile */}
                <div className="flex flex-row justify-between px-4 py-8">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" alt="Image"/>
                    </div>
                    <div className="max-[563px]:hidden"></div>
                    <div className="relative flex flex-row bg-[#191D24] border-[#252B35] broder-1 items-center justify-between px-2 py-1 rounded-xl gap-2" onClick={() => setHeaderFlag(flag => !flag)}>
                        {profile ? (<img src={`${BACKEND_URL_PURE}/${profile?.avatar}`} className="rounded-[9px] h-10 w-10" alt="Newbie" />) : (<img src="../assets/guest.png" className="w-8 h-8" alt="Image" />)}
                        <div className="flex flex-col justify-center gap-1 pl-2 cursor-pointer ">
                            {profile ? (<p className="text-white font-semibold text-[12px] leading-4">{profile?.username}</p>) : (
                                <p className="text-white">Guest</p>)}
                            <div className="flex flex-row">
                                <p className="  truncate font-normal text-[10px] text-[#637592] max-w-[90px] leading-4">{appContext?.paymentAddress} </p>
                                <IoCopyOutline onClick={handleCopyClick} style={{ color: "#637592" }} className="w-3.5 h-3.5" />
                            </div>
                        </div>
                        {headerFlag ?
                            <div className="absolute mt-12 left-0 right-0 top-0 rounded-[12px] py-2 flex flex-col bg-[#1d2027] border-t-2 border-[#26292e] text-white drop-shadow-xl">
                                {/* <div className="flex flex-row p-2 pl-5 gap-4 items-center hover:bg-[#212429] hover:brightness-125 duration-300 cursor-pointer">
                                    <FaRegCircleUser size={20} />
                                    <p className="text-[16px] font-semibold">Wallet Settings</p>
                                </div>
                                <div className="flex flex-row p-2 pl-5 gap-4 items-center  hover:bg-[#212429] hover:brightness-125 duration-300 cursor-pointer">
                                    <FaPlus size={20} />
                                    <p className="text-[16px] font-semibold">Add wallet</p>
                                </div> */}
                                <div className="flex flex-row p-2 pl-5 gap-4 text-red-600  items-center  hover:bg-[#212429] hover:brightness-125 duration-300 cursor-pointer"
                                    onClick={handleLogoutClick}
                                >
                                    <IoLogOutOutline size={20} />
                                    <p className="text-[16px] font-semibold">Log out</p>
                                </div>
                            </div> : <></>}
                    </div>
                </div>

                <div className="flex flex-col gap-6 px-4 ">

                    {/* back button and progress detial header */}
                    <div className='flex flex-row justify-between w-full gap-6'>
                        <div className='flex gap-4'>
                            <button className="flex flex-row items-center bg-[#191D24] rounded-xl px-4 py-2 max-[436px]:py-0  max-[436px]:px-2 gap-2 border-[1.5px] border-[#FFFFFF]" onClick={() => navigate('/in-progress')}>
                                <div className="flex items-center w-[16px] h-[16px] ">
                                    <img src="../assets/back.png" alt="Image"/>
                                </div>
                                <p className="text-[14px] text-white font-semibold leading-6 ">Back</p>
                            </button>
                            <p className="bold text-[32px] text-white leading-10">In Progress Details</p>
                        </div>

                        <div></div>
                    </div>

                    {/*  In Progress Details */}
                    <div className="flex flex-col gap-5 max-[922px]:items-center">

                        {/* wallet adress */}
                        <div className="flex bg-[#191D24] gap-4 max-w-[514px]  max-[925px]:w-[335px] rounded-xl p-4 border-2 border-[#252B35] ">
                            <div className="flex bg-[#637592] items-center bg-opacity-[8%] rounded-lg gap-2">
                                <img src="../assets/wallet.png" className="flex items-center w-10 h-10 p-2" alt="Image" />
                            </div>
                            <div className="flex flex-col gap-1 ">
                                <p className="font-bold text-[16px] leading-6 text-white">wallet</p>
                                <div className="flex flex-row items-center">
                                    <p className="text-[#637592] text-[16px] font-normal truncate max-w-[200px]">{appContext?.paymentAddress}</p>
                                    <IoCopyOutline style={{ color: "#637592" }} className="duration-300 cursor-pointer hover:brightness-200" onClick={handleCopyClick} />
                                </div>
                            </div>
                        </div>

                        {/* Transaction */}
                        <div className="flex flex-row items-center justify-between max-[925px]:w-[335px]">
                            <div className="flex flex-col justify-between">
                                <p className="text-[20px] font-bold leading-7.5 text-white">Transaction</p>
                                <p className="text-[#637592] text-[16px] font-normal truncate max-w-[200px]">{txFeature?.txDetails.txid}</p>
                            </div>
                            <div className="flex items-center bg-[#E78A00] bg-opacity-[20%] rounded-full px-4 py-1 gap-2">
                                <p className="text-[#E78A00] text-[16px] leading-6">{txFeature?.txDetails.status.confirmed ? 'Confirmed' : 'Unconfirmed'}</p>
                            </div>
                        </div>

                        <div className="flex flex-row max-[945px]:flex-col max-[925px]:w-[335px] justify-between bg-[#191D24] border-2 border-[#252B35] gap-8 py-3 px-6  rounded-xl ">

                            <div className="flex flex-col gap-2 rounded-xl">
                                <p className="text-[14px] text-[#637592] font-normal leading-5">First seen</p>
                                <p className="text-[14px]  text-white leading-5 ">{showFirstSeen()}</p>
                            </div>

                            <div className="flex flex-row items-center justify-between max-[945px]:w-[280px] bg-[#16171B] rounded-xl  py-2 px-4 h-[56px] gap-8">

                                <div className="flex flex-col">
                                    <p className="text-[14px] text-[#637592] font-normal leading-5">ETA</p>
                                    <p className="text-[14px]  text-white leading-5">3-5 mins</p>
                                </div>
                                <div></div>
                                <button className='flex items-center justify-center   bg-[#006B7A] rounded-xl py-1 px-2 w-[86px] h-[26px] gap-2'>
                                    <p className='flex items-center leading-2.5 text-white font-semibold text-[12px]' onClick={() => rbfModalHandler()}>
                                        Accelerate
                                    </p>
                                </button>

                            </div>

                            <div className="flex flex-col gap-2 ">
                                <p className="text-[14px] text-[#637592] font-normal leading-5">Features:</p>
                                <div className="flex flex-row gap-1">
                                    <div className={`${txFeature?.segwit ? 'bg-[#ffa53f]' : 'bg-red-700'} rounded-full px-1.5 py-0.5`}>
                                        <p className={`text-white text-[10px] font-medium leading-4`}>
                                            {txFeature?.segwit ? 'Segwit' : <del>Segwit</del>}
                                        </p>
                                    </div>
                                    <div className={`${txFeature?.taproot ? 'bg-[#ffa53f]' : 'bg-red-700'} rounded-full px-1.5 py-0.5`}>
                                        <p className={`text-[10px] text-white  font-medium leading-4`}>
                                            {txFeature?.taproot ? 'Taproot' : <del>Taproot</del>}
                                        </p>
                                    </div>
                                    <div className={`${txFeature?.rbf ? 'bg-[#ffa53f]' : 'bg-red-700'} rounded-full px-1.5 py-0.5`}>
                                        <p className={`text-[10px] font-medium text-white  leading-4`}>
                                            {txFeature?.rbf ? 'RBF' : <del>RBF</del>}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-2 rounde-xl">
                                <p className="text-[14px] leading-5 font-normal text-[#637592]">Fee</p>
                                <div className="flex flex-row gap-2">
                                    <p className="text-[14px] leading-5 font-semibold text-white">{txFeature?.fee} <span className="text-[#637592]"> sat</span></p>
                                    <p className="text-[14px] leading-5 font-semibold text-[#637592]">$ {showFeeUSD()}</p>
                                </div>
                            </div>

                            <div className="flex flex-col flex-wrap gap-2">
                                <p className="text-[14px] leading-5 font-normal text-[#637592]">Fee rate</p>
                                <div className="flex">
                                    <p className="text-[14px] leading-5 font-semibold text-white">{txFeature ? Math.floor(txFeature?.feeRate) : ''} <span className="text-[#637592]"> sat/vB</span></p>
                                </div>
                            </div>
                        </div>

                        {/* Input &  OutPuts */}

                        <div className="flex flex-col flex-wrap gap-4">
                            <p className="text-[20px] font-bold leading-7.5 text-white">Inputs & Outputs</p>
                            <div className="flex flex-col bg-[#191D24] border-2 border-[#252B35] rounded-xl gap-2 p-4">

                                <div className="flex flex-row justify-between gap-12">

                                    <div className='flex flex-col w-1/2'>
                                        {txFeature?.txDetails.vin.map(vin =>
                                            <div className='flex flex-row justify-between w-full'>
                                                <div className="flex flex-row gap-1">
                                                    <img src="../assets/arrow-circle-right.png" className="w-[24px] h-[24px]" alt="Image"/>
                                                    <p className="flex text-[14px] leading-5 font-semibold truncate text-gray-500">{vin.prevout.scriptpubkey_address}</p>
                                                </div>

                                                <div className="flex flex-row gap-1">
                                                    <p className="text-[14px]  text-white leading-5 font-semibold truncate max-[840px]:max-w-[50px]">
                                                        {(vin.prevout.value / (10 ** 8)).toFixed(8)}
                                                    </p>
                                                    <p className="text-[14px]  text-[#637592] leading-5 font-semibold" >BTC</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='flex flex-col w-1/2'>
                                        {txFeature?.txDetails.vout.map(vout =>
                                            <div className='flex flex-row justify-between w-full'>
                                                <div className="flex flex-row gap-1">

                                                    <p className="flex text-[14px] leading-5 font-semibold truncate text-gray-500">{vout.scriptpubkey_address}</p>
                                                </div>

                                                <div className="flex flex-row gap-1">
                                                    <p className="text-[14px]  text-white leading-5 font-semibold truncate max-[840px]:max-w-[50px]">
                                                        {(vout.value / (10 ** 8)).toFixed(8)}
                                                    </p>
                                                    <p className="text-[14px]  text-[#637592] leading-5 font-semibold" >BTC</p>
                                                    <img src="../assets/arrow-circle-right.png" className="w-[24px] h-[24px] ml-1" alt="Image"/>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                                <div className="flex flex-row justify-between">
                                    <div></div>
                                    <div className="flex flex-row bg-[#637592] bg-opacity-[8%] rounded-xl px-4  py-3 gap-1">
                                        <p className="text-[14px]  text-white leading-5 font-semibold">{showTotalValue()}</p>
                                        <p className="text-[14px]  text-[#637592] leading-5 font-semibold" >BTC</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* RBF History */}
                        {rbfHistory?.length ? <div className="flex flex-col justify-start gap-2 mt-4">
                            <p className="font-bold text-white text-[20px] leading-5">RBF History</p>

                            <div className='flex mx-auto mt-6 text-xl text-white'>
                                {showRbfPeriod()}
                            </div>
                            <div className='relative flex flex-row justify-between w-2/3 mx-auto'>
                                {rbfHistory.map((rbf: IRbf) => (
                                    <>
                                        <div className='flex flex-col items-center gap-1'>
                                            <div className='h-[30px] w-[30px] rounded-full bg-white border-4'></div>
                                            <div className='text-white'>{Math.floor(rbf.tx.rate)} sats / vB</div>
                                        </div>
                                        <div className='absolute w-11/12 h-3 -translate-x-1/2 -translate-y-1/2 bg-white left-1/2 top-4'></div>
                                    </>
                                ))}
                            </div>

                        </div> : <></>}
                    </div>

                    {showRBF ? (
                        <div className="fixed inset-0 flex flex-col top-20">
                            <div className="flex flex-col gap-2 bg-[#191D24] mx-auto border-2  max-[765px]:w-[343px] min-[765px]:w-[500px] border-solid border-[#252B35] rounded-xl p-6">
                                <div className="flex flex-row justify-between px-4 py-5">
                                    <div></div>
                                    <h3 className="text-[24px]  font-bold font-manrope text-white leading-8">RBF Speed Up</h3>
                                    <button onClick={() => closeHandle()}>
                                        <img src="../assets/close.png" alt="Image"/>
                                    </button>
                                </div>
                                <form encType='multipart/form-data'>
                                    <div className="flex flex-col gap-4">
                                        <div className="flex flex-col gap-1">
                                            <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]" >TransactionID</label>
                                            <p className="break-words text-white font-manrope text-[14px] leading-6 font-bold ">{row?.txId}</p>
                                        </div>

                                        <div className="flex flex-col gap-1">
                                            <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]">Destination Address</label>
                                            <p className="text-white font-manrope text-[14px] leading-6 font-semibold break-words">{row?.destinationAddress}</p>
                                        </div>

                                        {
                                            row?.type == "Ordinals" ?
                                                <div className="flex flex-col gap-1">
                                                    <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]">Inscription Id</label>
                                                    <p className="text-white font-manrope text-[14px] leading-6 font-semibold break-words">{row?.inscriptionId}</p>
                                                </div>
                                                :
                                                <div className="flex flex-col gap-1">
                                                    <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]">Transfer Amount</label>
                                                    <p className="text-white font-manrope text-[14px] leading-6 font-semibold break-words">{row?.amountToTransfer}</p>
                                                </div>
                                        }

                                        <div className="flex flex-col gap-1">
                                            <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]">Previous Fee Rate</label>
                                            <p className="text-white font-manrope text-[14px] leading-6 font-semibold break-words">{row?.feeRate}</p>
                                        </div>

                                        <div className="flex flex-col gap-1 ">
                                            <label className="font-manrope text-[14px] leading-6 font-normal text-[#637592]">Fee Rate</label>
                                            <input
                                                className="bg-[#16171B] rounded-xl px-4 py-3 gap-2 text-[#637592] focus:outline-none"
                                                placeholder="Input fee rate"
                                                ref={newFeeRef}
                                            ></input>
                                        </div>

                                        <div className="flex flex-row justify-between gap-1 pt-2">
                                            <label className="font-manrope text-[14px] leading-6 text-[#637592]">Estimated Time</label>
                                            <p className="break-words text-white font-manrope text-[14px] leading-6 font-semibold">10 mins</p>
                                        </div>

                                    </div>


                                </form>
                                <button
                                    className="bg-[#1665FF] rounded-xl px-6 py-3 w-full hover:bg-blue-700"
                                    onClick={() => rbfSubmitFunc()}
                                >
                                    <p className="text-white font-manrope text-[14px] font-semibold leading-6 ">RBF Submit</p>
                                </button>
                            </div>
                        </div>) : <></>}
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}

export default Details


