import Notiflix, { Loading } from "notiflix";

import Mydrawer from '../component/collapse'
import { ToastContainer } from 'react-toastify';
import Header from '../component/Header';
import { useContext, useRef } from "react";
import { runeBurnBraodcastingController, runeBurnController } from "../controller";
import { AppContext } from "../context/UserContext";


export default function RuneBurn() {

    Loading.custom({
        svgColor: "orange"
    })

    const appContext = useContext(AppContext);

    const runeNameRef = useRef(null);
    const runeAmountRef = useRef(null);

    const onSubmit = async () => {
        const runeId = (runeNameRef.current as any).value;
        const runeAmount = (runeAmountRef.current as any).value;

        if(!runeId) {
            return Notiflix.Notify.warning("runeId is required.")
        }
        if(!runeAmount) {
            return Notiflix.Notify.warning("Rune Amount is required.")
        }
        if(!appContext) {
            return Notiflix.Notify.warning("There is no Context here.");
        }

        console.log("Rune Burn Controller ==> ");
        const result = await runeBurnController(runeId, runeAmount, appContext?.ordinalsAddress, appContext?.ordinalsPublickey, appContext?.paymentAddress, appContext?.paymentPublickey);
        console.log("rune etching result ==> ", result);

        if(result.success) {
            Notiflix.Notify.success("Rune Etching Successfully. wait for 6 blocks")

            const singedPSBT = await (window as any).unisat.signPsbt(result.payload, {
                autoFinalized : false
            });
            console.log("singed PSBT ==> ", singedPSBT);

            const payload = await runeBurnBraodcastingController(singedPSBT, runeId);

            if(payload.success) {
                Notiflix.Notify.success("Rune Burnning is processing now.");
            } else {
                Notiflix.Notify.failure("Rune Burning failed...");
            }

            console.log("txId ==> ", payload);

        } else {
            Notiflix.Notify.warning("The server is busy now, plz try again later.")
        }
    }

    return (
        <div className="flex w-full min-h-screen mx-auto ">
            <div className="flex flex-col w-full min-h-screen">

                {/* user profile */}
                <div className="flex flex-row justify-between px-4 py-8 ">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" alt="Image" />
                    </div>
                    <div className="max-[563px]:hidden"></div>

                    {/* Header */}
                    <Header />
                </div>

                {/* Title */}
                <div className='flex flex-row justify-between px-8 '>
                    <p className="bold text-[32px] text-white leading-10">Rune Burn</p>
                </div>

                {/* Form */}
                <div className="flex flex-col gap-2 bg-[#191D24] mx-auto border-2 min-[640px]:w-[500px] max-[640px]:w-[450px] border-solid border-[#252B35] rounded-xl p-6 ">
                    <div className="flex flex-col justify-center">
                        <h3 className="text-[24px]  font-bold font-manrope text-white leading-8 text-center">Rune Burn</h3>
                        <div className="flex flex-row justify-between gap-4 pt-4">
                            <div className="flex flex-col gap-2 w-full pl-4">
                                <div className="flex flex-col gap-1">
                                    <label className="font-manrope text-[14px] font-normal leading-6 text-white">
                                        Rune Id
                                    </label>
                                    <input
                                        name="Rune"
                                        className="bg-[#16171B] rounded-xl p-2 gap-2 placeholder:text-gray-600 text-white focus:outline-none border-2 border-[#28292C]"
                                        placeholder="UNCOMMONGOODS"
                                        ref={runeNameRef}
                                    />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <label className="font-manrope text-[14px] font-normal leading-6 text-white">
                                        Rune Amount
                                    </label>
                                    <input
                                        name="RuneAmount"
                                        className="bg-[#16171B] rounded-xl p-2 gap-2 placeholder:text-gray-600 text-white focus:outline-none border-2 border-[#28292C]"
                                        placeholder="5000"
                                        ref={runeAmountRef}
                                    />
                                </div>
                                <button
                                className="bg-[#21262F] rounded-xl px-6 py-3 w-full hover:bg-[#21263E] mt-4"
                                type="submit"
                                onClick={() => onSubmit()}
                            >
                                <p className="text-white font-manrope text-[14px] font-semibold leading-6 ">Submit</p>
                            </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}

