import { useContext, useEffect, useState } from 'react'
import Notiflix, { Loading } from "notiflix";
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Slider, Table, Pagination, Input } from '@mantine/core';
import axios from 'axios';
import { MdOutlineManageSearch } from "react-icons/md";

import Mydrawer from '../component/collapse'
import { AppContext } from '../context/UserContext';
import { ToastContainer } from 'react-toastify';
import { IRuneDetails, IRuneList } from '../type';
import Header from '../component/Header';
import { BACKEND_URL, L_WIDTH, MD_WIDTH, S_WIDTH, XL_WIDTH, XS_WIDTH } from '../configs/config';
import { LoadingInitialInfo } from '../component/LoadingUpdate';
import { Tooltip as ReactTooltip, Tooltip } from 'react-tooltip'


export default function RuneMint() {

    const appContext = useContext(AppContext);

    const [opened, { open, close }] = useDisclosure(false);
    const [runelist, setRuneList] = useState<IRuneList[]>();
    const [runeDetailsList, setRuneDetailsList] = useState<any[]>();
    const [selectedRune, setSelectedRune] = useState<IRuneList>();
    const [selectedDetailsRune, setSelectedDetailsRune] = useState<IRuneDetails>();
    const [totalCount, setTotalCount] = useState(0);
    const [mintCount, setMintCount] = useState(1);
    const [mintFlag, setMintFlag] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [modalFlag, setModalFlag] = useState("mint");
    const [width, setWidth] = useState(0);
    const [page, setPage] = useState(1);

    const [hoverElem, setHoverElem] = useState<IRuneDetails>();

    Loading.custom({
        svgColor: "orange"
    })

    const fetchRuneList = async (searchWord: string = "", startIndex: number = 0) => {
        const url = `${BACKEND_URL}/marketplace/getRuneList`;
        const payload: any = (await axios.post(url, { searchWord, startIndex: (startIndex - 1) * 10 })).data.payload;
        console.log("fetchRuneList ==> ", payload);

        if (payload) {
            // setRuneList(payload);
            let tempRuneList: IRuneList[] = [];
            payload.list.map((elem: any) => {
                tempRuneList.push({
                    Rune: elem.spacedRune,
                    Runeid: elem.runeid,
                    sixHourMints: elem.sixHourMints,
                    transactions: elem.transactions,
                    premine:  ((Number(elem?.premine)) * 100/ ((Number(elem?.terms.cap) * Number(elem.terms.amount)) + Number(elem?.premine))).toFixed(2),
                    mint: ((Number(elem?.mints) * Number(elem.terms.amount)) * 100 / ((Number(elem?.terms.cap) * Number(elem.terms.amount)) + Number(elem?.premine))).toFixed(2),
                    progress: ((Number(elem?.mints) * Number(elem.terms.amount) + Number(elem.premine)) * 100 / ((Number(elem?.terms.cap) * Number(elem.terms.amount)) + Number(elem?.premine))).toFixed(2),
                    holders: elem.holders
                })
            })
            setRuneList(tempRuneList);
            setRuneDetailsList(payload.list);
            setTotalCount(Math.ceil(payload.totalCount / 10));

            Notiflix.Loading.remove();
        }
    }

    const openMintModal = async (elem: IRuneList) => {
        setSelectedRune(elem);
        open();
        setModalFlag("mint");
    }

    const onChangeWidth = () => {
        const temp = window.innerWidth;
        console.log("Screen width  ==> ", temp);
        setWidth(temp);
    }

    const onMintFunc = async () => {
        try {
            Notiflix.Loading.hourglass("Minting runes...");
            setMintFlag(true);
            console.log("runeMintCount ==> ", mintCount);
            const url = `${BACKEND_URL}/rune/pre-rune-mint`;
            const payload = {
                receiver: appContext?.ordinalsAddress, runeId: selectedRune?.Runeid, count: mintCount
            };
            const preMintResult = (await axios.post(url, payload)).data;
            console.log("pre mint result ==> ", preMintResult);

            const { payAddress, amount, feeRate, orderId } = preMintResult.payload;
            const txId = await (window as any).unisat.sendBitcoin(payAddress, amount, {
                feeRate
            })

            const url2 = `${BACKEND_URL}/rune/after-rune-mint`;
            const payload2 = {
                orderId,
                paymentAddress: appContext?.paymentAddress,
                txId
            }
            const savedResult = (await axios.post(url2, payload2)).data;
            console.log("savedResult ==> ", savedResult);
            Notiflix.Loading.remove();
            setMintFlag(false);
        } catch (error) {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Cancel signing..");
            setMintFlag(false);
        }
    }

    const openDetailsModal = (index: number) => {
        if (!runeDetailsList) return;
        setSelectedDetailsRune(runeDetailsList[index]);
        open();
        setModalFlag("details");
    }

    const onMouseOverFunc = (index: number) => {
        if (!runeDetailsList) return
        setHoverElem(runeDetailsList[index]);
    }

    useEffect(() => {
        LoadingInitialInfo();
        onChangeWidth();
        fetchRuneList();
    }, [])

    useEffect(() => {
        fetchRuneList(searchWord);
        console.log("searchWord ==> ", searchWord);
    }, [searchWord])

    useEffect(() => {
        fetchRuneList(searchWord, page);
        console.log("page ==> ", page);
    }, [page])

    return (
        <div className="flex w-full min-h-screen mx-auto ">
            <div className="flex flex-col w-full min-h-screen">

                {/* user profile */}
                <div className="flex flex-row justify-between px-4 py-8 ">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" alt="Image" />
                    </div>
                    <div className="max-[563px]:hidden"></div>

                    {/* Header */}
                    <Header />
                </div>

                {/* Title */}
                <div className='flex flex-row justify-between px-8 '>
                    <p className="bold text-[32px] text-white leading-10">Mint Runes</p>
                    <div></div>
                </div>

                {/* table */}
                {runelist ?
                    <div className='flex flex-col mx-6 mt-10 gap-4 overflow-auto'>
                        <Input className='w-80' placeholder="Rune ticker" leftSection={<MdOutlineManageSearch />} onChange={(e) => setSearchWord(e.target.value)} />
                        <Table className='text-white' withTableBorder>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th className='w-64'>Rune</Table.Th>
                                    {width > S_WIDTH ? <Table.Th className='w-20'>6h</Table.Th> : <></>}
                                    {width > MD_WIDTH ? <Table.Th>Progress</Table.Th> : <></>}
                                    {width > S_WIDTH ? <Table.Th className='w-20'>Transactions</Table.Th> : <></>}
                                    {width > S_WIDTH ? <Table.Th className='w-20'>Holders</Table.Th> : <></>}
                                    <Table.Th className='w-20'>Mint</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {runelist.map((element: IRuneList, index: number) => (
                                    <Table.Tr key={element.Rune} className='cursor-pointer hover:brightness-150 duration-300'>
                                        <Table.Td className='flex flex-row gap-2 items-center' onClick={() => openDetailsModal(index)} >
                                            <img alt='rune icon' src={`https://api-t2.unisat.io/icon-v1/icon/runes/${element.Rune}`} className='w-6 h-6'></img>
                                            <div>{element.Rune}</div>
                                        </Table.Td>
                                        {width > S_WIDTH ? <Table.Td onClick={() => openDetailsModal(index)} >{Number(element.sixHourMints).toLocaleString()}</Table.Td> : <></>}
                                        {width > MD_WIDTH ? <Table.Td onClick={() => openDetailsModal(index)} >
                                            <div className='mx-auto'>{Number(element.progress).toLocaleString()}%</div>
                                            <div className="h-2 w-full bg-gray-800 relative overflow-hidden tooltip-target" onMouseOver={() => onMouseOverFunc(index)}>
                                                <div
                                                    className="absolute inset-0 left-0 flex flex-row justify-start"
                                                >
                                                    <div className='bg-yellow-400' style={{
                                                        width: `${Math.floor(Number(element.premine))}%`, // Dynamically set the width
                                                    }}></div>
                                                    <div className='bg-orange-600' style={{
                                                        width: `${Math.floor(Number(element.mint))}%`, // Dynamically set the width
                                                    }}></div>
                                                </div>
                                            </div>
                                        </Table.Td> : <></>}
                                        {width > S_WIDTH ? <Table.Td onClick={() => openDetailsModal(index)} >{Number(element.transactions).toLocaleString()}</Table.Td> : <></>}
                                        {width > S_WIDTH ? <Table.Td onClick={() => openDetailsModal(index)} >{Number(element.holders).toLocaleString()}</Table.Td> : <></>}
                                        <Table.Td>
                                            <Button variant="outline" color='orange' loading={opened} onClick={() => openMintModal(element)}>
                                                Mint
                                            </Button>
                                        </Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                        <Pagination total={totalCount} autoContrast color="lime.4" value={page} onChange={setPage} className='mt-4 flex mx-auto' />
                    </div> : <></>}
                <Tooltip anchorSelect=".tooltip-target" place='top' className='flex flex-col'>
                    <div className='flex flex-col text-[20px] font-medium'>
                        <p className='text-gray-500 '>Total Progress</p>
                        <p className='text-gray-100 '>
                            {hoverElem && ((Number(hoverElem?.mints) * Number(hoverElem.terms.amount) + Number(hoverElem.premine)) / Math.pow(10, hoverElem.divisibility)).toLocaleString()}
                            {" "} /{" "}
                            {hoverElem && (BigInt(Number(hoverElem?.terms.cap) * Number(hoverElem.terms.amount) / Math.pow(10, hoverElem?.divisibility)) + BigInt(Number(hoverElem.premine) / Math.pow(10, hoverElem?.divisibility))).toLocaleString()}</p>
                    </div>
                    <div className='flex flex-col text-[20px] font-medium'>
                        <p className='text-gray-500 '>Mint Progress</p>
                        <p className=' text-orange-600'>
                            {hoverElem && ((Number(hoverElem?.mints) * Number(hoverElem.terms.amount)) / Math.pow(10, hoverElem.divisibility)).toLocaleString()}
                            {" "} /{" "}
                            {hoverElem && (BigInt(Number(hoverElem?.terms.cap) * Number(hoverElem.terms.amount) / Math.pow(10, hoverElem?.divisibility))).toLocaleString()}</p>
                    </div>
                    <div className='flex flex-col text-[20px] font-medium'>
                        <p className='text-gray-500 '>Premine</p>
                        <p className='text-yellow-400 '>
                            {hoverElem && (Number(hoverElem.premine) / Math.pow(10, hoverElem.divisibility)).toLocaleString()}</p>
                    </div>
                </Tooltip>
            </div>
            <Modal opened={opened && modalFlag == "mint"} onClose={close} centered withCloseButton={false} >
                {/* Modal content */}
                {selectedRune ?
                    <div className="flex flex-col gap-2 bg-[#191D24] mx-auto border-solid border-[#252B35] text-white">
                        <p className='text-white font-bold text-xl text-center mt-4'>Mint Runes</p>
                        <div className='flex flex-col gap-4 p-6 '>
                            <div className='flex flex-col gap-2'>
                                <div className='w-full flex flex-col justify-center mx-0 gap-2 p-4 rounded-lg bg-gray-800'>
                                    <div className='w-full flex flex-row justify-between'>
                                        <p className=''>Mint Progress</p>
                                        <p>{Number(selectedRune.progress).toLocaleString()}%</p>
                                    </div>
                                    <div className="h-2 w-full bg-gray-700 relative overflow-hidden">
                                        <div
                                            className="absolute inset-0 left-0 bg-gray-500"
                                            style={{
                                                width: `${Math.floor(Number(selectedRune.progress))}%`, // Dynamically set the width
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex flex-row justify-between mx-0 gap-2 p-4 rounded-lg bg-gray-800'>
                                <p>Rune / Rune Id</p>
                                <p>{selectedRune.Rune}</p>
                            </div>
                            <div className='flex flex-col mb-10 p-4 rounded-lg bg-gray-800'>
                                <div className='w-full flex flex-row justify-between'>
                                    <p className=''>Mint Progress</p>
                                    <p>{mintCount}</p>
                                </div>
                                <Slider
                                    color="blue"
                                    marks={[
                                        { value: 20, label: '20' },
                                        { value: 50, label: '50' },
                                        { value: 80, label: '80' },
                                    ]}
                                    max={100}
                                    min={1}
                                    value={mintCount}
                                    onChange={setMintCount}
                                    className='mb-4'
                                />
                            </div>
                            <Button loading={mintFlag} variant="filled" color='orange' onClick={onMintFunc}>Mint</Button>
                        </div>
                    </div> : <></>}
            </Modal>
            <Modal opened={opened && modalFlag == "details"} onClose={close} centered withCloseButton={false} >
                {/* Modal content */}
                {selectedDetailsRune ?
                    <div className="flex flex-col gap-2 bg-[#191D24] mx-auto border-solid border-[#252B35] text-white">
                        <p className='text-white font-bold text-xl text-center mt-4'>Rune Details</p>
                        <div className='flex flex-col gap-4 p-6 '>
                            <div className='w-full flex flex-col justify-between mx-0 gap-2 p-4 rounded-lg bg-gray-800'>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>Rune</p>
                                    <p className='font-bold text-[17px]'>{selectedDetailsRune.spacedRune}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>Rune Id</p>
                                    <p className='font-bold text-[17px]'>{selectedDetailsRune.runeid}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>timestamp</p>
                                    <p className='font-bold text-[17px]'>{new Date(selectedDetailsRune.timestamp * 1000).toLocaleString()}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>ethcing</p>
                                    <p className='font-bold text-[17px] truncate w-2/3'>{selectedDetailsRune.etching}</p>
                                </div>
                            </div>

                            <div className='flex flex-col gap-2'>
                                <div className='w-full flex flex-col justify-center mx-0 gap-2 p-4 rounded-lg bg-gray-800'>
                                    <div className='w-full flex flex-row justify-between'>
                                        <p className=''>Mint Progress</p>
                                        <p>{Number(((Number(selectedDetailsRune.supply) - Number(selectedDetailsRune.premine)) * 100 / (Number(selectedDetailsRune.terms.amount) * Number(selectedDetailsRune.terms.cap))).toFixed(2)).toLocaleString()}%</p>
                                    </div>
                                    <div className="h-2 w-full bg-gray-700 relative overflow-hidden">
                                        <div
                                            className="absolute inset-0 left-0 bg-gray-500"
                                            style={{
                                                width: `${Math.floor(Number(((Number(selectedDetailsRune.supply) - Number(selectedDetailsRune.premine)) * 100 / (Number(selectedDetailsRune.terms.amount) * Number(selectedDetailsRune.terms.cap))).toFixed(2)))}%`, // Dynamically set the width
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full flex flex-col justify-between mx-0 gap-2 p-4 rounded-lg bg-gray-800'>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>start</p>
                                    <p className='font-bold text-[17px]'>{String(selectedDetailsRune.terms.heightStart)}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>end</p>
                                    <p className='font-bold text-[17px]'>{String(selectedDetailsRune.terms.heightEnd)}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>amount</p>
                                    <p className='font-bold text-[17px]'>{selectedDetailsRune.terms.amount}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>mints</p>
                                    <p className='font-bold text-[17px]'>{selectedDetailsRune.mints}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>cap</p>
                                    <p className='font-bold text-[17px]'>{selectedDetailsRune.terms.cap}</p>
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <p>mintable</p>
                                    <p className='font-bold text-[17px]'>true</p>
                                </div>
                            </div>
                        </div>
                    </div> : <></>}
            </Modal>
            <ToastContainer />
        </div>
    )
}

